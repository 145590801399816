<template>
  <div
    :class="[
      'sideslip-image-dynamic',
      'sideslip-image-slider',
      isNeedCardBg ? 'has-padding' : '',
      intervalSpacing == '1' ? 'has-item-space' : '',
    ]"
    :style="[cardImgRadiusStyle(), { padding }]"
  >
    <!-- 横滑3.5、2.5 -->
    <div
      v-for="(item, i) in data"
      :key="i"
      :class="[`sideslip-image-slider__item-${type}`]"
      @click="(event) => clickItem(event, item, i)"
    >
      <BaseImg
        v-if="item.image"
        v-tap="getAnalysisData('2-22-2', { item, index: i, useBffApi: true,})"
        v-expose="getAnalysisData('2-22-1', { item, index: i, useBffApi: true,})"
        :brand="brand"
        :placeholder="{
          width: item.image.width,
          height: item.image.height,
        }"
        :style="[cardImgRadiusStyle(i), { overflow: 'hidden' }]"
        :special-radius="intervalSpacing == '1' ? cardImgRadiusStyle(i)?.borderRadius : '' "
        :ratio="item.image.ratio"
        :img-src="item.image.src"
        :img-design-width="216"
        :ada="item.ada"
        :first-screen="isFirstPage && i < ssrCount"
        :comp-src="compSrc"
        @baseImageMounted=" baseImageMounted()"
      />
    </div>
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import BaseImg from '../../base/BaseImg'
import mixin from '../../components/mixins/mixin'
import { isUnDef } from '@shein/common-function'

// 根据横滑类型映射图片样式类型
const COMPONENT_TYPE = {
  // dynamic
  MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC: 'three',
  ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE: 'three', // 静态3.5图
  ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE: 'two', // 静态2.5图
  ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE: 'four', // 静态4.5图
}
export default defineComponent({
  name: 'SideSlipImageSlide',
  directives: {
    expose,
    tap,
  },
  components: { BaseImg },
  mixins: [mixin],
  emits: ['baseImageMounted'],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    // 组件的位置
    index: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    reportTab: {
      type: Object,
      default: () => ({}),
    },
    brand: {
      type: String,
      default: 'shein',
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
    // 是否需要卡片背景
    isNeedCardBg: {
      type: Boolean,
      default: false,
    },
    // 页面全局配置
    extendData: {
      type: Object,
      default: () => ({}),
    },
    isCssRight: {
      type: Boolean,
      default: false,
    },
    // 无底衬时贴边,padding 代替 margin
    padding: {
      type: String,
      default: '',
    },
    compSrc: {
      type: String,
      default: '',
    }
  },
  data() {
    const {
      styleType = '',
      props: {
        metaData: { intervalSpacing = '0' },
      },
    } = this.propData || {}
    const type = COMPONENT_TYPE[styleType] || ''
    const ssrCount = this.calSsrCount(type)
    return {
      type,
      intervalSpacing, // 组件间隙留白
      ssrCount,
    }
  },
  computed: {
    isFirstPage() {
      return this.propData?.isFirstPage
    },
    // 卡片化图片圆角样式
    cardImgRadiusStyleMap() {
      const radius = this.cardImgRadius()
      const start = `${radius} 0 0 ${radius}`
      const end = `0 ${radius} ${radius} 0`
      return {
        start: {
          borderRadius: start,
        },
        end: {
          borderRadius: end,
        },
        all: {
          borderRadius: radius,
        },
      }
    },
  },
  methods: {
    // case1: 有间隙 - 全圆角
    // case2: 横滑4.5 - 每行首尾圆角
    // case3: 无间隙有标题 - 每行首尾圆角
    // case4: 无间隙无标题 - 整体圆角
    cardImgRadiusStyle(i) {
      let styleResult = {}
      const { intervalSpacing, type: rowType, cardImgRadiusStyleMap, data: viewList, isNeedCardBg } = this

      const hadSmallTitle = viewList.some((item) => item.smallTitle)
      const { start, end, all } = cardImgRadiusStyleMap
      // 没有卡片底衬时，最后一张图不需要右上、右下圆角（静态2.5、静态3.5）
      if (intervalSpacing == '1') {
        // case1
        styleResult = isUnDef(i) ? {} : all
      } else if (this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE') {
        // case2
        styleResult = i === 0 ? start : i === ((viewList?.length ?? 0) - 1)  ? end : {}
      } else if ((hadSmallTitle && !isUnDef(i))) {
        // case3
        const perRowNum = rowType === 'three' ? 3 : rowType === 'five' ? 5 : 0
        styleResult = i % perRowNum === 0 ? start : i % perRowNum === perRowNum - 1 ? end : {}
      } else {
        // case4
        styleResult = isUnDef(i) ? isNeedCardBg ? all : start : {}
      }
      if ((this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE' || this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE') && isUnDef(i)) {
        styleResult = {}
      }
      // 静态2.5、 3.5 卡片化无底衬无间隙、有底衬无间隙则整体圆角
      if ((this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE' || this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE') && !isUnDef(i) && (isNeedCardBg || intervalSpacing == '0') && (!isNeedCardBg || intervalSpacing == '0')) {
        let radius = {}
        if (this.isCssRight) {
          i === viewList.length - 1 ? radius = start : {}
          i === 0 ? radius = end : {}
        } else {
          i === viewList.length - 1 ? radius = end : {}
          i === 0 ? radius = start : {}
        }
        styleResult = radius
      }
      return styleResult
    },
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    clickItem(event, item, i) {
      // 阻止默认行为导致触发body scroll
      event.preventDefault()

      // from mixin
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
        isBff: true,
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },
    calcCateItem(item, itemsIndex, index) {
      // 计算item的位置,itemsIndex第几屏、chunkIndex第几个坑位
      const itemLoc = `${itemsIndex + 1}_${index + 1}`
      return { item_loc: itemLoc, ...item }
    },
    lastLine(i) {
      const lastNum = this.type === 'four' ? 4 : this.type === 'five' ? 5 : 0
      return this.data.length - i <= lastNum ? 'last-line' : ''
    },
    // 卡片底衬时：图片使用固定2px圆角；无底衬时：图片使用卡片圆角
    cardImgRadius() {
      const { cardRadius = 0 } = this.propData?.props?.metaData || {}
      return `${this.isNeedCardBg ? 2 : Number(cardRadius)}px`
    },
    calSsrCount(type) {
      const mapper = {
        'two': 3,
        'three': 4,
        'four': 5 
      }
      return mapper[type] ?? 5
    }
  },
})
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.sideslip-image-slider {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  line-height: 18px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  &__item-two {
    position: relative;
    display: inline-block;
    width: 144 / @vw;
    vertical-align: top;
  }

  &__item-three {
    position: relative;
    display: inline-block;
    width: 104 / @vw;
    vertical-align: top;
  }

  &__item-four {
    position: relative;
    display: inline-block;
    width: 84 / @vw;
    vertical-align: top;
  }

  .margin-right:not(:last-child) {
    margin-right: 8 / @vw;
  }
}

.has-item-space {
  padding: 0;
  .sideslip-image-slider__item-two,
  .sideslip-image-slider__item-three,
  .sideslip-image-slider__item-four {
    margin-right: 4 / @vw;
    &:last-child{
      margin-right: 0
    }
  }

  .sideslip-image-slider__item-two {
    width: 138 / @vw;
  }

  .sideslip-image-slider__item-three {
    width: 96 / @vw;
  }

  .sideslip-image-slider__item-four {
    width: 84 / @vw;
  }

  .sideslip-image-swiper {
    margin-bottom: -0.2133rem;
  }

  .sideslip-image-swiper__item-two,
  .sideslip-image-swiper__item-three,
  .sideslip-image-swiper__item-four,
  .sideslip-image-swiper__item-five {
    margin-bottom: 0.2133rem;
  }

  .last-line {
    margin-bottom: 0;
  }
}
.has-padding {
  padding: 0 4px;
}
</style>
