<template>
  <div
    :class="[
      'sideslip-image-swiper',
      intervalSpacing == '1' ? 'sideslip-image-swiper__interval-space' : '',
    ]"
    :style="[cardImgRadiusStyle(), { padding }]"
  >
    <!-- 横滑4 -->
    <div
      v-for="(item, i) in data"
      :key="i"
      :class="[`sideslip-image-swiper__item-${type}`, lastLine(i)]"
      @click="(event) => clickItem(event, calcCateItem(item, currentPageIndex, i), i)"
    >
      <img
        v-if="showMark(item)"
        class="sideslip-image__mark"
        :src="item.markType === 'new' ? newMarkImage?.src : hotMarkImage?.src"
      />
      <!--item_loc: `a_b_c` 表示第几屏、第几行、第几个坑位-->
      <BaseImg
        v-if="item.image"
        v-tap="
          getAnalysisData('2-22-2', {
            item: calcCateItem({...item,content_type: `${markStyle || ''}_${showMark(item) || ''}_${beltText(item)}` }, currentPageIndex, i),
            tab: { ...reportTab },
            act_nm: reportTab.tabText ? 'click_pic' : '-',
            index: i,
            useBffApi: true,
          })
        "
        v-expose="
          getAnalysisData('2-22-1', {
            item: calcCateItem({...item,content_type: `${markStyle || ''}_${showMark(item) || ''}_${ beltText(item) || ''}` }, currentPageIndex, i),
            tab: { ...reportTab },
            index: i,
            useBffApi: true,
          })
        "
        :style="cardImgRadiusStyle(i)"
        :brand="brand"
        :placeholder="{
          width: item.image.width,
          height: item.image.height,
        }"
        :ratio="item.image.ratio"
        :img-src="item.image.src"
        :img-design-width="180"
        :ada="item.ada"
        :first-screen="isFirstPage && currentPageIndex < 1"
        :comp-src="'side-slip-image'"
        @baseImageMounted="baseImageMounted()"
      />
      <!--      ccc配置了展示 并且 命中了试验 并且通过接口查询到有折扣信息才展示-->
      <div
        v-if="beltText(item)"
        class="sideslip-image__belt"
        :style="beltStyle"
      >
        {{ beltText(item) }}
      </div>
      <div
        v-if="item.smallTitle"
        class="sideslip-image__title"
        :class="{ 'sideslip-five': type === 'five' }"
        :style="{ color: cateNameColor, margin: beltText(item) ? '2px 4px 8px 4px' : '4px' }"
      >
        {{ item.smallTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import BaseImg from '../../base/BaseImg'
import mixin from '../../components/mixins/mixin'
import { isUnDef, template } from '@shein/common-function'

// 根据横滑类型映射图片样式类型
const COMPONENT_TYPE = {
  // dynamic
  MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC: 'four',
  MULTI_ITEMS_CAROUSEL_IND_DYNAMIC: 'four',
  MULTI_ITEMS_CAROUSEL_CON_DYNAMIC: 'four',
  // 静态横滑 4、5 图
  ITEM_IMAGE_FOUR_COLS_CAROUSEL: 'four',
  ITEM_IMAGE_FIVE_COLS_CAROUSEL: 'five',
}

export default defineComponent({
  name: 'SideSlipImage',
  directives: {
    expose,
    tap,
  },
  components: { BaseImg },
  mixins: [mixin],
  emits: ['baseImageMounted'],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    // 组件的位置
    index: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    reportTab: {
      type: Object,
      default: () => ({}),
    },
    brand: {
      type: String,
      default: 'shein',
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
    // 是否需要卡片背景
    isNeedCardBg: {
      type: Boolean,
      default: false,
    },
    // 页面全局配置
    extendData: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    }, 
    // 无底衬时贴边,padding 代替 margin
    padding: {
      type: String,
      default: '',
    },
  },
  data() {
    const {
      styleType = '',
      props: {
        metaData: { intervalSpacing = '0', displayElements = [], hotMarkImage, newMarkImage, beltImage, beltTextColor = '#dfc5bf',
          showCornerMark, showBelt, cateNameColor = '#222', markStyle  },
      }, 
    } = this.propData || {}
    return {
      type: COMPONENT_TYPE[styleType] || '',
      intervalSpacing, // 组件间隙留白
      displayElements, // ['hot', 'new', 'belt'], ccc配置的
      hotMarkImage,
      newMarkImage,
      beltImage,
      beltTextColor,
      // 通过ABTest控制是否显示角标
      showCornerMark,
      showBelt,
      cateNameColor,
      markStyle
    }
  },
  computed: {
    beltStyle() {
      return {
        color: this.beltTextColor,
        backgroundImage: `url(${ this.beltImage?.src })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    },
    // 展示角标开关
    showMark() {
      return (item) => {
        return (this.showCornerMark && this.displayElements.includes(item.markType)) ? item.markType : ''
      }
    },
    // 展示腰带开关
    beltText() {
      return (item) => {
        // ABT开关和CCC开关
        if(!(this.showBelt && this.displayElements.includes('belt'))){
          return ''
        }
        // 人工腰带
        if(item.beltLangText){
          return item.beltLangText
        }
        // 兜底-a.折扣值不存在 b.折扣值存在但小于30%，且无金额字段
        if(!item.bestDiscount || (item.bestDiscount < 30 && !item.lowestPrice)){
          return this.language?.SHEIN_KEY_PWA_26858
        }
        // 自动腰带-折扣值<30%时，显示金额
        if(item.bestDiscount < 30) { // 折扣值小于30%显示最低价
          return template(item.lowestPrice, this.language?.SHEIN_KEY_PWA_26712)
        }
        // 自动腰带-折扣值>=30%时，显示折扣
        return template(item.bestDiscount, this.language?.SHEIN_KEY_PWA_26713)
      }
    },
    isFirstPage() {
      return this.propData?.isFirstPage
    },
    // 卡片化图片圆角样式
    cardImgRadiusStyleMap() {
      const radius = this.cardImgRadius()
      const start = `${radius} 0 0 ${radius}`
      const end = `0 ${radius} ${radius} 0`
      return {
        start: {
          borderRadius: start,
        },
        end: {
          borderRadius: end,
        },
        all: {
          borderRadius: radius,
        },
      }
    },
    // case1: 有间隙 - 全圆角
    // case2: 无间隙有标题 - 每行首尾圆角
    // case3: 无间隙无标题 - 整体圆角
    cardImgRadiusStyle() {
      return (i) => {
        let styleResult = {}
        const { intervalSpacing, type: rowType, cardImgRadiusStyleMap, data: viewList } = this

        const hadSmallTitle = viewList.some((item) => item.smallTitle)
        const { start, end, all } = cardImgRadiusStyleMap
        if (intervalSpacing == '1') {
          // case1
          styleResult = isUnDef(i) ? {} : all
        } else if (hadSmallTitle && !isUnDef(i)) {
          // case2
          const perRowNum = rowType === 'four' ? 4 : rowType === 'five' ? 5 : 0
          styleResult = i % perRowNum === 0 ? start : i % perRowNum === perRowNum - 1 ? end : {}
        } else {
          // case3
          styleResult = isUnDef(i) ? all : {}
        }
        return styleResult
      }
    },
  },
  methods: {
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    clickItem(event, item, i) {
      // 阻止默认行为导致触发body scroll
      event.preventDefault()

      // from mixin
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
        isBff: true,
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },
    calcCateItem(item, itemsIndex, index) {
      // 计算item的位置,itemsIndex第几屏、chunkIndex第几个坑位
      const itemLoc = `${itemsIndex + 1}_${index + 1}`
      return { item_loc: itemLoc, ...item }
    },
    lastLine(i) {
      const lastNum = this.type === 'four' ? 4 : this.type === 'five' ? 5 : 0
      return this.data.length - i <= lastNum ? 'last-line' : ''
    },
    // 卡片底衬时：图片使用固定2px圆角；无底衬时：图片使用卡片圆角
    cardImgRadius() {
      const { cardRadius = 0 } = this.propData?.props?.metaData || {}
      return `${this.isNeedCardBg ? 2 : Number(cardRadius)}px`
    },
  },
})
</script>

<style lang="less" scoped>
.sideslip-image-swiper {
  .flexbox();
  .space-between();
  flex-wrap: wrap;
  overflow: hidden;

  &__item-four {
    position: relative;
    width: calc(~'100% / 4');
  }
  &__item-five {
    position: relative;
    width: calc(~'100% / 5');
  }
  .sideslip-image__mark {
    position: absolute;
    width: 0.64rem;
    height: 0.3rem;
    right: 0.32rem/*rtl:ignore*/;
    z-index: @zindex-hack;
  }
  .sideslip-image__belt {
    .line-camp(1);
    word-break: break-all;
    text-align: center;
    max-width: 2.1rem;
    min-width: 1.52rem;
    color: #BB472E;
    background-color: #dfc5bf;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: scale(0.75);
    padding: 0 4/75rem;
    margin: 0 auto;
    margin-top: -0.32rem;
  }
  .sideslip-image__title {
    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 28px;
    font-size: 12px;
    line-height: 14px;
    word-wrap: break-word;
    text-align: center;
    margin: 4px;
    .line-camp();
  }

  // /deep/ .base-img {
  //   overflow: unset;
  // }
}

.sideslip-image-swiper__interval-space {
  padding: 0;

  .sideslip-image-swiper__item-four {
    margin-bottom: 2px;
    width: calc(~'(100% - 0.32rem) / 4');
  }
  .sideslip-image-swiper__item-five {
    margin-bottom: 2px;
    width: calc(~'(100% - 0.32rem) / 5');
  }

  .last-line {
    margin-bottom: 0;
  }
}
</style>
