<template>
  <div
    v-if="viewList.length && isCardStyle"
    ref="sideslipImageRef"
    class="sideslip"
    :class="{
      'sideslip__card-bg': isNeedCardBg,
    }"
    :style="isNeedCardBg && cardStyle"
  >
    <!-- 顶部标题 -->
    <div
      v-if="titleStyle.titleText"
      class="sideslip__title"
      :style="{
        textAlign: titleStyle.titlePosition,
        color: titleStyle.titleColor,
      }"
    >
      {{ titleStyle.titleText }}
    </div>
    <!-- 无极横滑模式 -->
    <SideSlipMarquee
      :view-list="viewList"
      :scene-data="sceneData"
      :prop-data="propData"
      :cate-links="cateLinks"
      :index="index"
      :brand="brand"
      :is-need-card-bg="isNeedCardBg"
      :extend-data="extendData"
      :style="!isNeedCardBg && cardStyle"
      :language="language"
      class="sideslip__swiper"
      @baseImageMounted="baseImageMounted()"
    />
  </div>
  <!-- 切换静态组件 -->
  <SideSlipImageStatic
    v-else
    v-bind="$props"
    @baseImageMounted="baseImageMounted()"
  />
</template>

<script>
import { defineComponent } from 'vue'
import SideSlipMarquee from './SideSlipMarquee.vue'
import SideSlipImageStatic from 'public/src/pages/components/ccc/components/sideslip-image/Index.vue'

import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixin from '../../components/mixins/mixin'

// 静态横滑 4、5 图
const staticCompStyleType = [
  'ITEM_IMAGE_FOUR_COLS_CAROUSEL',
  'ITEM_IMAGE_FIVE_COLS_CAROUSEL', 
]
// 动态横滑多图
const dynamicCompStyleType = [
  'MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC',
  'MULTI_ITEMS_CAROUSEL_IND_DYNAMIC',
  'MULTI_ITEMS_CAROUSEL_CON_DYNAMIC',
]

export default defineComponent({
  name: 'SideSlipMarqueeCompose',
  components: {
    SideSlipMarquee,
    SideSlipImageStatic,
  },
  mixins: [mixin],
  emits: ['baseImageMounted'],
  props: {
    ...commonProps,
    // 静态横滑组件是否命中卡片化 abt
    isCardAbt: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      isFirstPage: false,
    }
  },
  data() {
    const {
      props: {
        metaData: {
          titleColor = '#222222',
          titlePosition = 'left',
          titleText = '',
          backgroundColor = '#fff',
          backgroundImg = {},
          isShowTitle,
        },
      },
    } = this.propData || {}
    const extendData = this.sceneData?.extendData || this.context?.content?.extendParse || {}
    return {
      titleStyle: {
        titleColor,
        titlePosition,
        titleText,
      },
      extendData, // 页面全局配置
      backgroundColor,
      backgroundImg,
      isShowTitle,
    }
  },
  computed: {
    viewList() {
      const items = []
      const { slides } = this.propData.props?.metaData
      const { items: propItems } = this.propData.props
      // this.propData.props.items 按照 slides 的个数按照顺序进行分组
      if (Array.isArray(propItems)) {
        const perList = Math.ceil(propItems.length / slides)
        for (let i = 0; i < slides; i++) {
          items[i] = {
            items: propItems.slice(i * perList, (i + 1) * perList)
          }
        }
      }
      return items
    },
    // 静态组件卡片化通过 abt 控制
    isStaticCardStyle() {
      return this.isCardAbt 
    },
    isCardStyle() {
      if (staticCompStyleType.includes(this.propData.styleType)) {
        return this.isStaticCardStyle
      }
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        return this.isDynamicCardStyle
      }

      return false
    },
    // 动态组件卡片组件接全局配置: 卡片化字段为false，或者边距、圆角 这2项都获取失败或为空时，代表非卡片化样式，展示原有样式
    isDynamicCardStyle() {
      const {
        isCardShow = '0',
        margin: marginList = [],
        cardRadius = 0,
      } = this.propData?.props?.metaData || {}
      if(isCardShow == '0') return false
      if (isCardShow == '1' || cardRadius > 0) return true
      return Array.isArray(marginList) && marginList.some((item) => item > 0)
    },
    hadSmallTitle() {
      return this.viewList.some((item) => {
        if (!Array.isArray(item?.items)) return false
        return item?.items.some((i) => i.smallTitle)
      })
    },
    isNeedCardBg() {
      if (staticCompStyleType.includes(this.propData.styleType)) {
        // 无标题不需要卡片底衬
        if (this.isStaticCardStyle && this.hadSmallTitle) return true
        return false
      }
      // 配置标题、tab、文本相关则需要卡片背景
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        return Boolean(this.hadSmallTitle || this.titleStyle?.titleText)
      }
      
      return false
    },
    cardStyle() {
      // 无极横滑模式且无底衬时，使用padding代替margin
      const type = !this.isNeedCardBg ? 'padding' : 'margin'
      let bgStyle = {}
      
      if(this.isNeedCardBg && dynamicCompStyleType.includes(this.propData.styleType)){
        // 新增背景图/色
        bgStyle = {
          backgroundColor: this.backgroundColor,
          backgroundImage: `url(${this.backgroundImg?.src})`,
          backgroundSize: '100% 100%',
        }
      }
      // 静态横滑 4、5 图有背景图色配置
      if(this.isNeedCardBg && staticCompStyleType.includes(this.propData.styleType)) {
        bgStyle = {
          backgroundColor: this.backgroundColor,
        }
      }
      return { ...this.getCardStyle(type), ...bgStyle }
    },
  },
  methods: {
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    // 获取卡片化样式
    getCardStyle(type = 'margin') {
      // 1.如果全局配置中有卡片化配置，则动态组件使用全局配置中的卡片化配置
      // 2.如果全局配置中有卡片化配置，则静态组件命中abt时使用全局配置中的卡片化配置
      // 3.如果全局配置中没有卡片化配置，则静态组件命中abt时使用默认的兜底卡片化配置 cardRadius=3, margin=[0, 8, 10, 8]

      // 静态组件
      if (staticCompStyleType.includes(this.propData.styleType)) {
        const defaultMargin = [0, 8, 10, 8]
        const defaultCardRadius = 3

        const { isCardShow = false, margin = [], cardRadius = 0 } = this.propData?.props?.metaData || {}
        const marginList =
          isCardShow && Array.isArray(margin) && margin.length === 4
            ? margin.slice()
            : defaultMargin
        const marginStyle = marginList
          .map((item) => `${Number(item)}px`)
          .join(' ')
        
        const styleResult = {
          [type]: marginStyle
        }

        if (this.isNeedCardBg) {
          styleResult.borderRadius = isCardShow ? `${cardRadius}px` : `${defaultCardRadius}px`
        }
       
        return styleResult
      }

      // 动态组件
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        const { margin = [], cardRadius = 0 } = this.propData?.props?.metaData || {}
        const marginList =
          Array.isArray(margin) && margin.length === 4
            ? margin.slice()
            : Array(4).fill(0)
        const marginStyle = marginList
          .map((item) => `${Number(item)}px`)
          .join(' ')
        const styleResult = {
          [type]: marginStyle
        }
        if (this.isNeedCardBg) {
          styleResult.borderRadius = `${Number(cardRadius)}px`
        }
        return styleResult
      }
      
      return {}
    },
  },
})
</script>

<style lang="less" scoped>
.sideslip {
  overflow: hidden;

  &__card-bg {
    padding: 4px 0;
    background-color: #fff;

    .sideslip__tab {
      margin-top: -4px;
    }

    .sideslip__swiper {
      padding: 0 4px;
    }

    .sideslip__title {
      margin: 4px 0 8px;
      padding: 0 6px;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      font-weight: 700;
      .line-camp(1);
    }
  }

  :deep(.base-img__href) {
    margin-bottom: -1px;  // 1px以防露出间隙、黑边
  }
}

</style>
